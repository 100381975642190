<template>
  <div>
    <div class="Done">
      <div class="mainBox">
        <div class="form join done">
          <div class="logoBox">
            <a class="logo"
              ><img
                src="@/assets/images/reviewmind/logo2.png"
                alt="리뷰마인드 홈"
            /></a>
          </div>
          <div class="centerTxt">
            <h2>환영합니다<span>!</span></h2>
            회원가입이 완료되었습니다.
            <br />리뷰마인드 회원이 되신것을 진심으로 환영합니다.
          </div>

          <div class="btnWrap">
            <button class="btn full primary" @click="submit">로그인</button>
            <!-- <a href="/login">로그인</a> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { setNavbar } from "@/mixins/setNavbar";
import { setFooter } from "@/mixins/setFooter";
import { mapState } from "vuex";
export default {
  mixins: [setNavbar, setFooter],
  data() {
    return {};
  },
  mounted() {
    this.setNavbar(false);
    this.setFooter(false);
  },
  computed: {
    ...mapState(["authData"]),
  },
  methods: {
    submit() {
      window.location.href = "/login";
    },
  },
  beforeDestroy() {
    this.setNavbar(true);
    this.setFooter(true);
  },
};
</script>
